<template>
  <div class="setpassword content_getresponse !bg-[#F1F2F7]">
    <div class="setpassowrd_inner container !rounded-[16px] !max-w-[30rem] ">

      <div class=" flex flex-col justify-center items-center gap-y-3 ">
        <p class="text-[#3C4549] font-poppins pt-[1.188rem] text-[16px] font-bold leading-5">Connect Sendy</p>
        <p class=" text-[14px] font-normal font-poppins text-[#757A8A] text-center">Enter Your Sendy Account Details
          below to connect your Sendy account.</p>
      </div>

      <transition name="slide">
        <div v-if="!show_lists" class="px-8 pb-6 pt-10 flex flex-col justify-center items-center gap-y-3 ">
          <FloatingLabelInput
            id="name"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="Sendy Account Name"
            placeholder="Account Name"
            v-model="name"
          >
          </FloatingLabelInput>
          <FloatingLabelInput
            id="listId"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="Sendy List ID"
            placeholder="The ID of the list where you want to add subscriber"
            v-model="listId"
          >
          </FloatingLabelInput>
          <FloatingLabelInput
            id="listName"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="Sendy List Name"
            placeholder="The Name of the list where you want to add subscriber"
            v-model="listName"
          >
          </FloatingLabelInput>
          <FloatingLabelInput
            id="api"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="Sendy API Key"
            placeholder="API Key"
            v-model="api"
          >
          </FloatingLabelInput>
          <FloatingLabelInput
            id="url"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="Sendy URL"
            placeholder="URL"
            v-model="url"
          >
          </FloatingLabelInput>
          <div class=" mt-3  flex gap-x-3 justify-between items-center">
            <Button
              id="login-button"
              type="button"
              class="!border-[1.5px] !border-[#3C4549] !py-[1.142rem]  !text-[14px] !font-semibold !rounded-[0.571rem] hover:bg-[#F2F3F8]"
              buttonClass="btn-lg"
              @click="$router.push({name: 'integrations'})"
            >
              <template v-slot:label>Close</template>
            </Button>
            <Button
              id="API"
              type="button"
              class="py-[1.14rem] !text-[14px] !font-semibold !text-white !rounded-[8px] bg-[#2560D7] hover:bg-[#1d4ed8]"
              buttonClass="btn-lg"
              :disabled="disable_connect"
              @click.prevent="setSendy()">
              <template v-slot:label>Connect</template>
            </Button>
          </div>
        </div>
      </transition>

      <transition name="slide">
        <form v-if="show_lists" class="basic_form clear !py-3" action="javascript:;">

          <ul v-if="lists" class="check_list">
            <li v-for="list in lists" class="checkbox">
              <input v-model="selected_lists" type="checkbox" :value="list" :id="list.id">
              <label :for="list.id">{{ list.name }}</label>
            </li>
          </ul>
          <div class="col-md-12 text-center btn_sec">
            <Button
              id="Save"
              type="button"
              class="py-[1.14rem] !text-[14px] !font-semibold !text-white !rounded-[8px] bg-[#2560D7] hover:bg-[#1d4ed8]"
              buttonClass="btn-lg"
              @click.prevent="saveSendyAccount()">
              <template v-slot:label>Save</template>
            </Button>
          </div>
        </form>

      </transition>

    </div>
  </div>
</template>
<script>
import {
  SendyConnectURL
} from '@/config/config.js'
import {mapGetters} from 'vuex'
import http from '@/mixins/http-lib'

export default (
  {
    name: 'Sendy',
    components: {
      FloatingLabelInput: () => import('@/ui/ui-kit/v2/FloatingLabelInput.vue'),
      Button: () => import('@/ui/ui-kit/v2/Button.vue')
    },
    data() {
      return {
        api: null,
        url: null,
        listId: null,
        listName: null,
        lists: [],
        show_lists: false,
        selected_lists: [],
        disable_connect: false,
        name: null,
        reconnect: false,
        account_id: ''

      }
    },
    async created() {
      await this.getUserProfile()
      await this.fetchWhitelabelAppSettings()
    },
    computed: {
      ...mapGetters(['getProfile', 'getWorkspace'])
    },
    methods: {

      setSendy() {
        if (this.api == '') {
          this.alertMessage('Please enter your Sendy API Key', 'error')
          return
        }
        if ((this.url.trim()).length == 0) {
          this.alertMessage('Please enter your Sendy URL', 'error')
          return
        }
        if ((this.listId.trim()).length == 0) {
          this.alertMessage('Please enter your Sendy List ID', 'error')
          return
        }
        if ((this.listName.trim()).length == 0) {
          this.alertMessage('Please enter your Sendy List Name', 'error')
          return
        }
        if ((this.name.trim()).length == 0) {
          this.alertMessage('Please enter your Sendy Account Name', 'error')
          return
        }
        this.disable_connect = true

        let localStorageWorkspace = JSON.parse(localStorage.getItem('workspace'))
        let brand_id = this.getWorkspace && this.getWorkspace._id ? this.getWorkspace._id : localStorageWorkspace._id

        http.post(SendyConnectURL, {
          api: this.api,
          url: this.url,
          listId: this.listId,
          listName: this.listName,
          name: this.name,
          brand_id: brand_id
        }).then(
          response => {
            if (response.data.tokenError && response.data.tokenError == true) {
              this.alertMessage(response.data.message, 'error')
              this.disable_connect = false
            } else {
              if (response.data.status) {
                this.alertMessage(response.data.message, 'success')
                this.getUserProfile()
                this.$router.push({
                  name: 'integrations'
                })
              } else {
                this.alertMessage(response.data.message, 'error')
              }
              this.disable_connect = false
            }
          },
          response => {
            this.disable_connect = false
            this.alertMessage('Unknown error occurred, support has been notified.', 'error')
          })
      }

    }
  }
)
</script>
